body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
}

a {
  color: var(--link-color);
}

p {
  color: var(--secondary-color);
}


.accent-text {
  color: var(--accent-color);
}

.important-text {
  color: var(--imp-text-color);
}

.section-background {
  background-image: var(--section-background-color);
}

.image-overlay {
  background-image: var(--image-gradient);
}

.background-gradient {
  background-image: var(--background-gradient);
}

.highlight {
  color: var(--imp-text-color);
}

.shadow-text {
  text-shadow: 1px 1px 2px var(--shadow-color);
  font-weight: bold;
  padding-top: 10px;
}

button:focus {
  box-shadow: none !important;
}

.rounded-img {
  border-radius: 10px;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--loading-color);
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}


/* --------- */
/* Go to top   */
/* --------- */

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  opacity: 0; /* Initial opacity value */
  transition: opacity 0.3s ease-in-out; /* Transition effect for opacity */
}

.scroll-to-top.show {
  opacity: 1;
}


.scroll-to-top-button {
  background-color: var(--primary-color);
  border: none;
  color: var(--bg-color);
  cursor: pointer;
  font-size: 24px;
  width: 40px; /* Set the width and height to be the same to create a circle */
  height: 40px;
  line-height: 40px; /* Center the content vertically */
  text-align: center; /* Center the content horizontally */
  border-radius: 50%;
  display: flex; /* Use flex to center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.scroll-to-top-button:hover {
  background-color: var(--link-color);
}



/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lighter-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-hover-color);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: var(--navbar-color) !important;
  transition: all 0.3s ease-out 0s;
  box-shadow: 0px 10px 10px 0px var(--shadow-color) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: relative;
  transition: all 0.3s ease-out 0.2s !important;
  z-index: 10;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: var(--bg-color) !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.5s ease-in-out !important;
  transition: transform 0.5s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: var(--navbar-color) !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

@media screen and (max-width: 768px) { /* Adjust the breakpoint according to your needs */
  .navbar-scrolled {
    background: #000; /* Your desired background color */
    transition: background 0.3s; /* Optional transition effect */
  }
}

.navbar-brand {
  color: green !important;
}

.logo {
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .logo {
    max-height: 60px;
    max-width: 60px;
  }
}


.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: var(--accent-color);
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}



.nav-item-switch {
  padding-left: 1rem;
  padding-right: 1rem;
}


.nav-container {
  display: flex;
  align-items: center;
}

.switch-label {
  display: inline-block;
}

.toggle-container {
  display: flex;
  align-items: center;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url(./Assets/home-bg.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

.heading {
  font-size: 2.4em;
  padding-left: 50px;
}

.section-heading {
  text-align: center;
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 2.4em;
  font-weight: 500;
}

.title-background {
  background: rgba(255, 255, 255, 0.2); 
  padding-top: 20px; /* Padding around the title */
  padding-bottom: 1px;
  border-radius: 10px; /* Rounded corners */
  text-align: center; /* Center align the text */
  margin-top:20px;
  margin-bottom:20px;
}

.section {
  position: relative;
  padding: 150px 0 30px;
  color: white;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: var(--accent-color);
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: var(--text-color) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: var(--text-color) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--primary-color);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--accent-color);
}

.home-social-icons:hover {
  color: var(--accent-color);
  box-shadow: 0 0 5px var(--accent-color);
  text-shadow: 0 0 2px var(--accent-color);
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-color {
  color: var(--text-color) !important;
}

.home-icon-color {
  color: var(--lighter-color) !important;
}


/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: var(--bg-color);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: var(--text-color) !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: var(--imp-text-color) !important;
}
/* --------- */
/* Projects */
/* --------- */

.project-card {
  perspective: 1500px;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%; /* This ensures a square aspect ratio */
  margin: 15px 0; 
  transition: all 0.4s ease 0s;
}

.project-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 4px 5px 4px 3px var(--shadow-color);
  border-radius: 5px;
  transform-style: preserve-3d;
  transition: all 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
}

.project-card:hover .project-inner {
  transform: rotateY(180deg);
}

.project-front, .project-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 5px;
}

.project-front {
  background: var(--section-background-color); /* Add a semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.project-back {
  background: var(--bg-color); /* Darker background for readability */
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.project-front img {
  max-width: 90%;
  max-height: 70%;
  margin: 10px;
}

.project-back p {
  color: var(--text-color);
  padding: 15px;
  text-align: center;
}
  

/* --------- */
/* About */
/* --------- */

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

.tech-icons {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech-card {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.tech-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.tech-icons:hover .tech-card-inner {
  transform: rotateY(180deg);
}

.tech-card-front,
.tech-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.tech-card-back {
  color: white;
  transform: rotateY(180deg);
}

.tech-card-back p {
  font-size: 1.5rem !important;
  margin: 0;
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative;
  padding-bottom: 40px;
  padding-top: 150px;
  background-image: var(--section-background-color);
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}


.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  padding: 8px 15px;
  display: inline-block;
  margin-bottom: 10px;
}


.react-pdf__Page__textContent {
  display: none;
}


.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: var(--accent-color) !important;
  border-color: var(--accent-color) !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: var(--button-hover-bg-color) !important;
  border-color: var(--button-hover-bg-color) !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-top:1rem;
  margin-bottom: 1rem;
}

.fork-btn-inner {
  font-size: 1.1em !important;
  line-height: 1.4em !important;
  background-color: var(--button-bg-color) !important;
  border-color: var(--button-bg-color) !important;
  padding: 0.25rem 1.1rem !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: var(--button-hover-bg-color) !important;
  border-color: var(--button-hover-bg-color) !important;
}

.fork-btn-inner::after {
  display: none !important;
}


/* --------- */
/* Blogs */
/* --------- */

.blog-card {
  padding: 50px 25px;
  height: auto;
  margin: 15px;
  perspective: 1000px;
}

.blog-card-view {
  background-color: transparent;
  box-shadow: 0 3px 3px 2px var(--shadow-color);
  color: var(--text-color);
  transition: all 0.5s ease;
  height: 100%;
}

.blog-card-view:hover {
  transform: scale(1.02);
  overflow: hidden;
  box-shadow: 0 3px 3px 5px var(--shadow-color);
}

.blog-link {
  color: var(--link-color);
  text-decoration: none;
}

.blog-link:hover {
  cursor: pointer;
}

.blog-img {
  padding: 0;
  opacity: 0.8;
  border-radius: 0;
}

.blogs-section {
  position: relative;
  padding: 150px 0 30px;
  color: white;
}

.blog-card-inner {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.blog-card:hover .blog-card-inner {
  transform: rotateY(180deg);
}

.blog-card:hover {
  border: 2.2px solid rgba(197, 115, 230, 0.883);
}

.blog-card-front,
.blog-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.blog-card img {
  width: 80%;
  height: auto;
}

.blog-card-back {
  padding: 10px;
  transform: rotateY(180deg);
}
