@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

/* Light theme (default) */
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--background-gradient);
}

/* Dark theme */
body.dark {
  background-image: var(--background-gradient);
}
